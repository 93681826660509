:root {
  --font-family: "Roboto", Sans-serif;
  --mainColor: #273450;
  --bgColor: #F1F5F6;
  --primaryColor: #1C7DFF;
}

.loading {
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 50px;
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: grey;
  display: inline-block;
  height: 7px;
  margin: 4px;
  width: 7px;
  border-radius: 50px;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% {
    background-color: grey;
    transform: scale(1);
  }

  50% {
    background-color: gray;
    transform: scale(1.3);
  }

  100% {
    background-color: grey;
    transform: scale(1);
  }
}


.rotate-icon {
  transition: transform 0.2s;
}

.open .rotate-icon {
  transform: rotate(45deg);
}

.custom-speed-dial {
  position: relative;
}

.speed-dial-button {
  width: 58px;
  height: 58px;
  background-color: var(--mainColor);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.speed-dial-button.open {
  transform: rotate(92deg);
}

.chat-widget {
  position: absolute;
  top: 70px;
  right: 20px;
  width: 300px;
  height: 400px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}


.custom_chat_main_div {
  position: fixed;
  right: 20px;
  bottom: 0;
  margin: 0 60px 50px 0;
  height: 65vh;
  width: 55vh;
  bottom: 35px;
  border: 1px solid gainsboro;
  border-radius: 5px;
  background-color: var(--bgColor);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  min-width: 320px !important;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: white;
  border-bottom: 2px solid #e5e7eb;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.custom_chat_open_btn {
  position: fixed;
  right: 15px;
  bottom: 0;
  margin: 0 20px 20px 0;
  cursor: pointer;
  z-index: 999 !important;
}

.custom_chat_header_div {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  background: white;
  height: 52px;
  width: 100%;
  background-color: var(--mainColor);
}

.custom_chat_header_inner {
  display: flex;
  margin-left: 23px;
  align-items: center;
  justify-content: center;
  color: white;
}

.custom_chat_header_inner>p {
  padding-top: 24px;
  padding-left: 10px;
  font-size: 20px;
  color: white !important;
  font-weight: bold;
  font-family: var(--font-family);

  /* font-family: "Urbanist", Sans-serif; */
}

.custom_chat_bot_close_icon {
  font-size: 24px;
  position: absolute;
  right: 10px;
  color: black;
}

.custom_chat_body_div {
  height: 100%;
  overflow-y: auto;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  background-color: var(--bgColor);
  padding-left: 10px;
  padding-right: 10px;
}

.custom_chat_user_prompt {
  float: right;
  word-wrap: break-word;
  color: var(--mainColor);
  background-color: var(--primaryColor) !important;
  border-radius: 10px;
  max-width: 71%;
  padding: 0px 6px;
  margin: 0px 0px 8px auto;
  text-align: left;
  border: 1px solid var(--primaryColor);
  white-space: pre-wrap;
  font-family: inherit;
}

.custom_chat_user_prompt_para {
  margin: 10px 10px 10px 0px;
  font-family: inherit;
  font-weight: 400;
  text-align: justify;
  color: var(--bgColor);
  padding: 0px !important;
}

.custom_message_prompt_para {
  margin: 5px 5px 5px -9px;
  font-family: inherit;
  font-weight: 400;
}

.custom_chat_sender_prompt {
  border-radius: 15px;
  background-color: white;
  border: 1px solid #dddddd;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  max-width: 71%;
  white-space: pre-wrap;
}

.custom_chat_sender_prompt p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 18px;
  letter-spacing: 0.28px; */
  margin: 0px !important;
  padding: 2px !important;
}

.custom_ai_avatar {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
  border: 2px solid #0a70ff;
  padding-top: 2px;
}

.ant-avatar>img {
  margin-top: 2px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.typing-loader {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 20px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #333;
  border-radius: 50%;
  margin: 0 3px;
  animation: bounce 1.3s linear infinite;
}

.dot:nth-child(2) {
  animation-delay: -1.1s;
}

.dot:nth-child(3) {
  animation-delay: -0.9s;
}

@keyframes bounce {

  0%,
  60%,
  100% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-5px);
  }
}

.custom_chat_bot_prompt {
  border-radius: 1px;
  background-color: white;
  max-width: 45vh;
  padding: 0px 14px 0px;
  text-align: left;
  word-wrap: break-word;
  display: flex;
  margin: 10px auto 10px 10px;
  white-space: pre-wrap;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.custom_chat_bot_avatar {
  border-radius: 50px;
  background: var(--mainColor);
  width: 40px;
  height: 40px;
  margin-left: 10px;
  margin-top: 12px;
}

.rotate-45-degrees {
  transform: rotate(-30deg);
}

.custom_chat_loader {
  margin: 15px auto;
  width: 33px;
  height: 7px;
  background: rebeccapurple;
  background-size: calc(100% / 3) 100%;
  background-repeat: no-repeat;
  animation: dots-zcf63l 1s infinite linear;
}

.custom_chat_bot_icon {
  /* color: var(--mainColor) !important; */
  font-size: 20px !important;
  cursor: pointer;
  /* margin-right: 6px; */
  color: white !important;

}

.custom_chat_footer_div {
  position: fixed;
  margin-bottom: 17px;
  /* width: 425px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
  /* background-color: white; */
  height: 60px;
  /* border: 0.5px solid #d9d9d9; */
  padding: 4px;
  padding-right: 10px;
  height: 90px;
  border-radius: 6px;
}

.custom_chat_footer_div:focus {
  border: 0.5px solid #40a9ff !important;
  outline: none;
  box-shadow: none !important;
}

.chat_input:focus {
  outline: none;
}

.chat_input::placeholder {
  font-size: 17px !important;
  color: rgb(104, 102, 102) !important;
  border: none !important;
  font-family: var(--font-family);
}

.chat_input {
  background-color: #fff;
  color: black;
  border: 0;
  border: none !important;
  /* padding: 8px 5px; */
  width: 640px !important;
  font-size: 17px;
  border-radius: 10px;
  max-width: 100% !important;
  height: 80px;
  border: 1px solid #dddddd !important;
}

/* new */
.chat-input-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
}

.chat_input {
  flex-grow: 1;
  min-height: 35px;
  max-height: 100px;
  padding: 10px;
  padding-right: 82px;
  border: none;
  resize: none;
  line-height: 1.5;
  font-size: 14px;
}

.send-button {
  position: absolute !important;
  right: 20px;
  bottom: 20px;
  min-width: 76px !important;
  width: 76px;
  height: auto !important;
  border-radius: 25px !important;
  background-color: #0096FF !important;
  color: white !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.send-button:hover {
  background-color: #007acc !important;
}

.send-button:disabled {
  background-color: #A1AEB5 !important;
  cursor: not-allowed;
}

@media (max-width: 480px) {
  .chat-input-container {
    padding: 5px;
  }

  .chat_input {
    font-size: 16px;
  }

  .send-button {
    bottom: 10px;
  }
  
  .custom_chat_main_div.open {
    height: 75% !important;
  }
}

@media (max-width: 991px) {
  .custom_chat_main_div.open {
    height: 75% !important;
  }
}

.custom_chat_main_div {
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transform: scale(0.9);
  pointer-events: none;
}

.custom_chat_main_div.open {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
  display: flex;
  justify-content: flex-end;
}

.speed-dial-button {
  transition: transform 0.3s ease-in-out;
}

.speed-dial-button.open {
  transform: rotate(180deg);
}

.message-container {
  animation: slideIn 0.5s ease-out forwards;
}

/* .message-bubble {
  animation: bubbleBounce 0.1s ease-out forwards;
} */

.avatar-container {
  position: relative;
  margin-right: 6px;
}

.user-avatar-container {
  position: relative;
  margin-left: 6px;
}

.ai-avatar::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(0, 150, 255, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: ripple 1s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bubbleBounce {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes ripple {
  to {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

/* ... keep existing CSS for typing indicator ... */

.typing-indicator {
  background-color: #e6e6e6;
  will-change: transform;
  width: auto;
  border-radius: 50px;
  padding: 10px;
  display: table;
  margin: 0 auto;
  position: relative;
  animation: 2s bulge infinite ease-out;
}

.typing-indicator span {
  height: 6px;
  width: 6px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}

.typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}

.typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}

.typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.9999s;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}



@media only screen and (max-width: 800px) {
  .custom_chat_main_div {
    position: fixed;
    /* margin: 0px; */
    /* height: 468px; */
    right: 15;
    /* bottom: 60px; */
  }

  .custom_chat_header_div {
    border-radius: 0px;
  }

  .custom_chat_footer_div {
    margin: 0px;
    border-radius: 0px;
  }
}

@media only screen and (max-width: 470px) {
  .custom_chat_bot_avatar {
    display: none;
  }

  .custom_chat_main_div {
    width: 346px;
    right: 0;
  }

  .custom_chat_footer_div {
    width: 346px;
  }
}

.time-c {
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: flex-end;
}

.time-c>*:not(:last-child) {
  margin-right: 4px;
}

.logo-main {
  display: flex;
  align-items: center;
}

.border-header {
  border-bottom: 1px solid #dddddd;
}

.icon-container {
  margin-top: 5px;
  margin-right: 3px;
}

.recatcha-main {
  height: 100% !important;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}


.user-avatar {
  width: 34px;
  height: 34px;
  color: black;
  background-color: #F1F5F6;
  margin-left: 6px;
  border: 2px solid orange;
  line-height: 37px;
  font-size: 16px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-message-main {
  height: 44vh;
  overflow-y: auto;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  background-color: var(--bgColor);
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
}

.info-message-main p {
  font-size: 20px;
  font-family: revert;
  text-align: center;
}