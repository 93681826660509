.markdown-gem pre {
    background-color: #1e1e1e;
    padding: 15px;
    margin: 30px;
    overflow: auto;
    border-radius: 3px;
    position: relative;
}

.markdown-gem pre code {
    color: #abb2bf !important;
    background-color: #1e1e1e !important;
    padding: 1em !important;
    border-radius: 5px !important;
    overflow-x: auto !important;
    display: block !important;
    font-family: 'Consolas', 'Monaco', monospace !important;
    font-size: 12px !important;
    line-height: 1.2 !important;
}

.copy-button {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 4px 8px;
    font-size: 12px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
}


.markfont p {
    padding: 0px !important;
    margin: 5px !important;
    /* font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px; */
}